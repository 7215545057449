@import-normalize; /* bring in normalize.css styles */
@import url('https://fonts.googleapis.com/css?family=Roboto|Leckerli+One&display=swap');

@import 'src/theme/styles/utils.scss';
@import 'src/theme/styles/base.scss';

/* rest of app styles */
 autoprefixer {grid: autoplace;}
:root {
  font-size: 62.5%;
}

@font-face {
   font-family: 'Roboto', sans-serif;
   src: url("fonts/Roboto-Regular.ttf") format('truetype');
   font-style: normal;

   font-family: 'DINPro';
   src: local('DINPro'), url('fonts/DINPro-Regular.woff') format('truetype');
   font-style: normal;
}


body [data-reach-dialog-overlay] {
  z-index: 22000 !important;
}
