@import "../../theme/styles/variables";
@import "../../theme/styles/common.scss";

.intro-heading{
    @extend%flexcol-layout;
    justify-content: center;
    align-items: center;
    h2{
        font-size: calc(#{3.7rem} + 2vmin);
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: $semibold;
        letter-spacing: 0.05em;
        color: rgba(0, 0, 0, 0.9);
        // margin-top: -0.7em;
    }
    h3{
        font-size: calc(#{2.2rem} + 2vmin);
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: $bold;
        text-align: center;     
        color: rgba(0, 0, 0, 0.9);
        line-height: 47px;
        padding: 0em 1em;
    }
}
.specialist-intro-text{
    @extend%flexcol-layout;
    justify-content: center;
    p{
        margin-bottom: 1.4em;
    }
    & .audio-icon{
        @extend%flexrow-layout;
        justify-content: flex-start;
        align-items: center;
        p{
            margin: 0;
            font-family: $main-font-family;
            font-style: normal;
            font-weight: $regular;
            font-size: calc(#{$f-8} + 2vmin);
            line-height: 165%;
            color: rgba(0, 0, 0, 0.5);
            padding-left: 0.5em;
        }
    }
    & .type-span{
        font-family: $main-font-family;
        font-style: italic;
        font-weight: $light;
        font-size: calc(#{$f-6} + 2vmin);
        color: rgba(0, 0, 0, 0.7);
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        margin-left: -0.5em;
    }
    & .meaning-info1{
        font-family: $main-font-family;
        font-style: normal;
        font-weight: $semibold;
        font-size: calc(#{$f-6} + 2vmin);
        line-height: 140%;
        color: #000000;
    }
    & .meaning-info2{
        font-family: $main-font-family;
        font-style: italic;
        font-weight: $regular;
        font-size: calc(#{$f-6} + 2vmin);
        line-height: 140%;
        color: rgba(0, 0, 0, 0.7);
    }
    & .meaning-info3{
        font-family: $main-font-family;
        font-style: normal;
        font-weight: $regular;
        font-size: calc(#{$f-6} + 2vmin);
        line-height: 120%;
        color: #000000;
        margin: 0;
    }
}

@media (max-width: 320px) {
    .intro-heading h2{
        font-size: calc(#{2.5rem} + 2vmin);
    }
}

@media (min-width: 641px) {}

@media (min-width: 768px) {}