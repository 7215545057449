@import "../../theme/styles/variables";
@import "../../theme/styles/common.scss";

.main-wrapper{
    @extend%flexcol-layout;
    justify-content: flex-start;
    align-items: center;
    background-color: #F3F3F3;
    // min-height: -webkit-fill-available;
    min-height: 94vh;
    overflow: hidden !important;
    border-radius: 16px;

    & .main-logo{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 1.5em;
        img{
            // width: 100%;
        }
    }

    & .page-heading{
        display: flex;
        justify-content: center;
        // padding-top: 1em;
        h1{
            font-family: $secondary-font-family;
            font-style: normal;
            font-weight: $regular;
            font-size: calc(#{$f-18} + 2vmin);        
            text-align: center;
            text-transform: uppercase;  
            color: $black-color;
            margin: 0;
                }
        }
        & .invitation-text{
            display: flex;
            justify-content: center;
            width: 85%;
            margin-top: 1em;
            p{
                font-family: $main-font-family;
                font-style: normal;
                font-weight: $regular;
                font-size: calc(#{$f-9} + 2vmin);
                line-height: 20px;
                text-align: center;
                color: $black-color;
                margin: 0;
                // padding-bottom: 0.5em;
            }
        }
        & .order-number{
            @extend%flexcol-layout;
            justify-content: center;
            width: 100%;
            align-items: center;
            & .circle-icon{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 6%;
                height: 24px;
                border-radius: 12px;
                background-color: rgba(0, 0, 0, 0.7);
                color: $white-color;
                font-family: $main-font-family;
                font-style: normal;
                font-weight: $regular;
                font-size: calc(#{$f-10} + 2vmin);
            }
            & .customer-info{   
                width: 100%;
                display: flex;
                justify-content: center;
                p{
                    font-family: $main-font-family;
                    font-style: normal;
                    font-weight: $regular;
                    font-size: calc(#{$f-8} + 2vmin);
                    text-align: center;
                    color: rgba(0, 0, 0, 0.9);
                    padding-bottom: 1.5em;
                    margin: 0;
                }
            }
        }
        & .meet-movers{
            @extend%flexcol-layout;
            justify-content: center;
            align-items: center;
            padding: 1em 1em 0em 1em;
            p{
                font-family: $main-font-family;
                font-style: normal;
                font-weight: $regular;
                font-size: calc(#{$f-8} + 2vmin);
                text-align: center;
                color: rgba(0, 0, 0, 0.9);
                margin: 0;
                line-height: 20px;
            }
        }
        & .invitation-btn{
            @extend%flexcol-layout;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 1em;
        }
        
}