.title {
	color: #fff;
	padding: 20px;
	margin: 0 auto;
	line-height: 1.2;
}

.title h2 {
	font-size: 1.3em;
}

.title h3 {
	font-size: 0.7em;
}

h1,
h2 {
	margin: 12px 0;
}

h3 {
	font-weight: normal;
	text-transform: uppercase;
	opacity: 0.6;
	margin: 0;
}

h2 {}

.title>* {
	max-width: 60vw;
}

@media screen and (min-width: 641px) {
	.title>* {
		max-width: 30vw;
	}
}