@import "../../theme/styles/variables";
@import "../../theme/styles/common.scss";


  .player-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 1.5em;
    margin-bottom: 1.5em;
    width: 100%;
    height: 37vh;
    padding-left: 2em;
    padding-right: 2em;
    // position: relative;
    /* padding-top: 56.25%; */
    
  
    &  .react-player {
      display: flex;
      justify-content: center;
      // position: absolute;
      // top: 0;
      // left: 0;
    }
  }


  
   
 