@import "../../theme/styles/variables";
@import "../../theme/styles/common.scss";

.mover-heading{
    display: flex;
    justify-content: flex-start;
    padding-left: 0.5em;

    h2{
      font-family: $main-font-family;
      font-style: normal;
      font-weight: 600;
      font-size: calc(#{$f-6} + 2vmin);
      line-height: 18px;
      color: rgba(0, 0, 0, 0.9);
    }
  }
   .experience-list{
    display: flex;
    justify-content: flex-start;
    ul{
      padding-left: 2.5em;
      li{
        font-family: $main-font-family;
        font-style: normal;
        font-weight: 600;
        font-size: calc(#{$f-6} + 2vmin);
        line-height: 27px;       
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
  .add-oppertunity{
    display: flex;
    justify-content: flex-start;
      p{
          font-family: $main-font-family;
          font-style: normal;
          font-weight: 600;
          font-size: calc(#{$f-6} + 2vmin);
          line-height: 18px;    
          color: rgba(0, 0, 0, 0.9);
          margin: 0;
      }
  }