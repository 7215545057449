@import 'src/theme/styles/variables';

// .thankyou {
//   background-color: $color-gray-bg;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// //  padding-bottom: 60px;
//   flex: 1;

//   .confirm-message{
//     width: 100%;

//   }
// }
%flexcol-layout{
  display: flex;
  flex-direction: column;
  
}
%flexrow-layout{
  display: flex;
  flex-direction: row;
}
%flex-layout{
    display: flex;
    justify-content: center;
    align-items: center;
}
%text-style{
  color: rgb(0, 0, 0);
  font-size: calc(#{$f-10} + 2vmin);
  font-Weight: $regular;
  font-family: $main-font-family;
  margin: 0px;
}
.page-title{
    @extend%flex-layout;
    padding: 1em;
      p{
        @extend%text-style;
        font-size: calc(#{$f-18} + 2vmin);
        text-align: center;
        text-transform: uppercase;
      }

}
.bottom-text{
  @extend%flex-layout;
  padding: 3em 1em 2em 1em;
  width: 100%;
  p{
    @extend%text-style;
    line-height: 24px;
    text-align: center;
  }
}
