@import 'src/theme/styles/variables';

%bedsize-style {
	display: flex;
	height: 80px;
	border: 1px solid #E2E2E2;
	align-items: center;
	flex-direction: row;
}

%sizelist-style {
	list-style: none;
	color: #000000;
	font-family: $dinpro-font-family;
	font-size: calc(#{$f-2} + 2vmin);
	text-transform: uppercase;
}

.title-container {
	display: flex;
	flex-direction: column;
	// background-color: #f3f3f3;
}

.form-title {
	display: flex;
	// background-color: #f3f3f3;
	justify-content: center;
	align-items: center;
	.title {
		@extend %bedsize-style;
		// background-color: $movesize-bgcolor;
		justify-content: center;
		flex: 1 1 auto;
		margin: 0;
		text-transform: uppercase;
		font-size: calc(#{$f-17} + 2vmin);
		font-weight: $medium;
		color: #212529;
		font-family: $secondary-font-family;
		border: none;
		text-align: center;
		padding: 0.5em;
		p {
			@extend %sizelist-style;
			margin: 0;
		}
	}
}

.form-subtitle {
	// background-color: #f3f3f3;
	height: 3vh;
	margin-top: -11px;
	margin-bottom: 22px;
	justify-content: center;
	align-items: center;
	display: flex;
	.subtitle {
		font-size: calc(#{$f-9} + 2vmin);
		font-family: $main-font-family;
		text-align: center;
		text-transform: inherit;
		color: #212529;
		font-weight: $regular;
	}
}

@media (min-width: 1024px) {
	.form-title {
		.title {
			font-size: calc(#{$f-9} + 2vmin) !important;
		}
	}
	.form-subtitle {
		margin-top: -10px !important;
		margin-bottom: 22px !important;
		.subtitle {
			font-size: calc(#{$f-5} + 2vmin) !important;
		}
	}
}