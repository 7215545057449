main.frozen {
	position: fixed;
	overflow: hidden;
	height: 100vh;
	pointer-events: none;
}

header {}

.list {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
}

.list>div {
	margin: 20px;
	flex: 0 0 calc(100% - 40px);
	height: 350px;
	border-radius: 16px;
	overflow: hidden;
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.25);
	background-size: cover;
	background-position: center;
}

.shadow {
	box-shadow: 0 0px 16px rgba(0, 0, 0, 0.25);
}

.main-container {
	padding-right: 1.3em;
    padding-left: 1.3em;
    /* padding-bottom: 3em; */
    padding-top: 0;
	height: 90vh;
}

.title-text {
	padding: 20px;
	color: #fff;
}

.title-text h2 {
	font-size: 1.3em;
}

.title-text h3 {
	font-size: 0.7em;
}

.icon-user {
	float: right;
}

.buttons {
	float: right;
	padding-top: 0px;
	padding-right: 0px;
	font-size: 0.8rem;
}

button {
	border: 1px solid #ACACAC;
	background-color: #ffffff;
	border-radius: 6px;
}

.today-date h1 {
	font-size: 2em;
}

.today-date h3 {
	font-size: 0.8em;
}

@media screen and (min-width: 768px) {
	header {}
	.today-date {
		width: 50%;
		float: left;
	}
	.rc-autoresponsive-container {
		padding-top: 1em
	}
}

@media screen and (max-width: 414px) {
	header {}
	.buttons {
		font-size: 0.7rem;
	}
	.today-date {
		width: 40%;
		float: left;
	}
	.today-date h1 {
		font-size: 1em;
	}
	.rc-autoresponsive-container {
		padding-top: 1em;
	}
	.comfort-btn {
		/* padding-top: 1.2em; */
	}
}

@media screen and (min-width: 641px) {
	main {}
	.list>div {
		flex: 0 0 calc(40% - 40px);
	}
	.list>div:nth-child(4n + 1),
	.list>div:nth-child(4n + 4) {
		flex: 0 0 calc(60% - 40px);
	}
}

@media screen and (min-width: 1024px) {
	main {}
	.list>div {
		flex: 0 0 calc(40% - 40px);
	}
	.list>div:nth-child(4n + 1),
	.list>div:nth-child(4n + 4) {
		flex: 0 0 calc(60% - 40px);
	}
	.comfort-btn {
		padding-top: 0.5em;
	}
	.rc-autoresponsive-container {
		padding-top: 0.9em;
	}
	
	
}