@import 'src/theme/styles/variables';
.coupon-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.coupon-text {
  text-align: center;
  font-weight: $regular;
  font-size: calc(#{$f-10} + 2vmin);

  // margin: 1.5em auto;
  margin-top: 0.5em;

  span {
    display: block;
    color: $primary;
    font-family: $secondary-font-family;
  }


&.discount {

  .discount-amount {
    font-weight: $bold;
    font-size: calc(#{6rem} + 2vmin);
    vertical-align: middle;
    padding-right: 1rem;
    line-height: 1;


    &:before {
      content: '$';
      font-size: calc(#{2rem} + 2vmin);
      position: relative;
      top: -23px;
      left: 0;
      font-weight: $semibold;
    }

  }

  .discount-condition {
    font-weight: $semibold;
    font-size: calc(#{$f-15} + 2vmin);
  }

}

  &.instruction {
    margin-top: 0.3em;
    margin-bottom: 0.9em;
  }

}


@media (min-width: 768px) {

.coupon-text {
  font-size: calc(#{$f-8} + 2vmin) !important;
}

}

@media (min-width: 1024px) {

.coupon-text {
   &.instruction {
        margin-bottom: 1.2em;
      }
    }
}
