@import 'src/theme/styles/variables';

%flexcol-layout{
    display: flex;
    flex-direction: column;
    
  }
  %flexrow-layout{
    display: flex;
    flex-direction: row;
  }
  %flex-layout{
      display: flex;
      justify-content: center;
      align-items: center;
  }
  %text-style{
    color: rgb(0, 0, 0);
    font-size: calc(#{$f-10} + 2vmin);
    font-Weight: $regular;
    font-family: $main-font-family;
    margin: 0px;
  }
.card-img{
    @extend%flex-layout;
    width: 100%;

    img{
        max-width: 90%;
    }
}
.card-heading{
    @extend%flex-layout;
    margin-top: 2em;
    p{
        @extend%text-style;
        font-style: italic;
    }
}
.intro-text{
    @extend%flex-layout;
    margin-top: 2em;
    width: 100%;
    p{
        @extend%text-style;
        font-size: calc(#{$f-6} + 2vmin);
        line-height: 24px;
        text-align: center;

    }
}