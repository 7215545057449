@import "../../theme/styles/variables";

%flexcol-layout{
    display: flex;
    flex-direction: column;
    
}
%flexrow-layout{
    display: flex;
    flex-direction: row;
}
%text-style{
    color: rgb(0, 0, 0);
    font-size: calc(#{$f-10} + 2vmin);
    font-Weight: $regular;
    font-family: $main-font-family;
    margin: 0px;
}
.card-container{
    @extend %flexcol-layout;
    justify-content: center;
    background-color: white;
    width: 93%;
    border-radius: 10px;
    margin-top: 1em;
    padding: 3em 2em 3em 2em;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 1px, 
    rgba(0, 0, 0, 0.04) 0px 2px 6px, rgba(0, 0, 0, 0.04) 0px 10px 20px;

}