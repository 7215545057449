.video-screen-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #F3F3F3;
    // min-height: -webkit-fill-available;
    min-height: 94vh;
    overflow: hidden !important;
    border-radius: 16px;
}

    .video-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1em;
      }