@import "../../theme/styles/variables";

  .location-input{
    display: flex;
    flex-direction: column;
    // align-items: center;
    font-family: $main-font-family;
      font-style: normal;
      font-weight: $regular;
      padding-bottom: 0.2em;
      padding-left: 2.6em;
      padding-right: 4em;
      background: $white-color;

      & .MuiInputBase-input {
        font-size: calc(#{$f-9} + 2vmin) !important;}
  }
  .loading-option{
      font-family: $main-font-family;
      font-style: normal;
      font-weight: $regular;
      font-size: 1.6em;
      padding-bottom: 0.2em;
  }

 
  
  
  @media (max-width: 480px) {
    .autocomplete-container {
      text-align: left;
    }
  }
  
  @media (min-width: 768px) {
    .alert {
      margin-left: auto;
      margin-right: auto;
      width: 50%;
      -webkit-animation: fadein .75s;
      -moz-animation: fadein .75s;
      -ms-animation: fadein .75s;
      -o-animation: fadein .75s;
      animation: fadein .75s;
    }
  
    .btn {
      margin-top: 1.5rem;
    }
  
    .form-group {
      display: inline-block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5rem;
      width: 50%;
    }
  }