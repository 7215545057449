@import 'src/theme/styles/variables';

%coupon-style {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  %text-style {
    font-family: $secondary-font-family;
    font-style: normal;
    text-align: center;
  }
#coupon {
  background-color: $color-gray-bg;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 11em;
  flex: 1;

  &.comfort-btn{
    padding-top: 15%;
}

}
@media (min-width: 320px) {}

@media (min-width: 641px) {}

@media (min-width: 768px) {
}

@media (min-width: 1024px) {
    #coupon {
        &.comfort-btn{
            padding-top: -10em !important;
        }

     p{
         font-size: calc(#{$f-8} + 2vmin) !important;
                
        }

    }

    .promo-code{
           
         .h2{
             font-size: calc(#{$f-8} + 2vmin) !important;
         }
         h2{
             font-size: calc(#{$f-8} + 2vmin) !important;
         }
         h1{
            font-size: calc(#{5rem} + 2vmin) !important;
            span{
                font-size: calc(#{1.5rem} + 2vmin) !important;
             }
        }
         }

         .offer{
            p{
                font-size: calc(#{$f-6} + 2vmin) !important;
            }
        }
 }