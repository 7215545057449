@import 'src/theme/styles/variables';
.comfort-btn {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	// padding-top: 3em;
	width: 100%;
}

.MuiButton-root {
	padding: 8px 16px !important;
}

@media screen and (max-width: 414px) {}
