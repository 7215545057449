@import 'src/theme/styles/variables';
#login-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #F3EFE4;
	.signin-logo {
		display: flex;
		padding-top: 11.09%;
	}
	.text-field {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		padding-top: 18%;
	}
	.login-button {
		width: 100%;
		padding-top: 6%;
	}
	.line-view {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding-top: 4%;
		width: 95%;
		.social-seperator {
			height: 2px;
			width: 18.67%;
			opacity: 0.5;
			background-color: #B1B1B1;
		}
		.info-text {
			padding-left: 10px;
			padding-right: 10px;
			padding-top: 9px;
			p {
				font-size: calc(#{$f-4} + 2vmin);
				color: #909090;
				font-family: $main-font-family;
				line-height: 14px;
				text-align: center;
				text-transform: uppercase;
			}
		}
	}
	.social-btn {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 83%;
		.google {
			position: absolute;
			left: 48px;
			padding-top: 6px;
			z-index: 100;
			color: #ffffff;
			font-size: 3rem;
		}
		.facebook {
			position: absolute;
			right: 162px;
			padding-top: 8px;
			z-index: 100;
			color: #ffffff;
			font-size: 2.3rem;
		}
	}
	.signup-info {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding-top: 35%;
	}
	p {
		font-size: calc(#{$f-10} + 2vmin);
		color: #909090;
	}
	.signup-text {
		font-size: calc(#{$f-10} + 2vmin);
		color: #232B41;
		font-weight: 700;
		text-decoration: underline;
		text-transform: uppercase;
	}
	.MuiButton-label {}
	.text-field {
		form {
			background-color: #F3EFE4 !important;
		}
	}
}

.login-background {
	background-color: #F3EFE4;
}

@media screen and (max-width: 320px) {
	#login-container {
		.facebook {
			left: 166px !important;
		}
		.google {
			left: 35px !important;
		}
		.MuiButton-root {
			padding-left: 27px !important;
			padding-top: 2px !important;
		}
	}
}

@media screen and (min-width: 1024px) {
	#login-container {
		max-width: 450px;
		margin: 0 auto;
		background-color: #F3EFE4;
		.makeStyles-textField-2 {}
		.text-field {
			form {
				background-color: #F3EFE4 !important;
				width: 29vw !important;
			}
		}
		.google {
			left: 477px !important;
			padding-top: 35px !important;
		}
		.facebook {
			right: 596px !important;
			padding-top: 32px !important;
		}
	}
}