@import 'src/theme/styles/variables';

.form-container {
	display: flex;
	flex-direction: row;
}

*:focus {
	outline: none;
}

.form-section {
	position: relative;
	background-color: #f3f3f3;
	height: 100vh;
}
.header-section{
	position: fixed; 
	z-index: 1000; 
	left: 0;
	right: 0; 
	background-color: #f3f3f3;
}
.section-padding{
	// padding-top: 7.3em;
	padding-top: 8.3em;
}
.main-footer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	position: fixed;
	bottom: 0;
	width: 100%;
	min-height: 10vh;
	background-color: #ECECEC;
	.movesize-container {
		width: 100% !important;
	}
	.select-list{
		border-bottom: none !important;
		height: 10vh;
	}
	p {
		text-align: center;
		font-size: calc(#{$f-4} + 2vmin);
		text-transform: uppercase;
		color: $black-color;
		padding-left: 1%;
	}
	.footer-icon{
		position: absolute;
		right: 21px;
	}
	.icon-arrow-right{
		font-size: 27px !important;
	}
}

@media screen and (min-width: 1024px) {
	.main-footer {
		.select-list {
			height: 10vh !important;
		}
		.footer-icon{
			right: 21px;
		}
  }
  .section-padding{
	padding-top: 7em;
}
}