%flexcol-layout{
    display: flex;
    flex-direction: column;
    
}
%flexrow-layout{
    display: flex;
    flex-direction: row;
}
%text-style{
    color: rgb(0, 0, 0);
    font-size: calc(#{$f-10} + 2vmin);
    font-Weight: $regular;
    font-family: $main-font-family;
    margin: 0px;
}

.error{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0.5em;
    width: 80%;
}
%input-textstyle{
    font-size: calc(#{$f-9} + 2vmin) !important;
    color: #333333 !important;
    font-family: $main-font-family !important;
    font-weight: $regular !important;
    font-style: italic !important;
}
%inputlayout{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    background-color: #fff;
    // padding: 2em;
    width: 100%;
}

.add-ambassador-info{
    // @extend%inputlayout;
    @extend %flexcol-layout;
    justify-content: center;
    background-color:#FBFBFB;
    width: 100%;
    border-radius: 10px;
    margin-top: 1em;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 2px 6px, rgba(0, 0, 0, 0.04) 0px 10px 20px;
    padding: 0em 1.5em 3em 1.5em;
    margin: 0 auto;

    & .MuiFormControl-root{
        width: 70vw;
        margin-bottom: 1.5em;
    }
    & .MuiInputLabel-root {
        @extend%input-textstyle;
    }
    & .MuiInput-underline:before{
        border-bottom: 1px solid #CFD4DA;   
     }
     & .MuiSvgIcon-root {
        font-size: 2rem;}
     & .company-info{
        display: flex;
        justify-content: center;
        width: 90%;
        padding-left: 3em;
    }
    & .ambassador-type{
        display: flex;
        justify-content:center;
        padding-left: 0em;
        width: 100%;
        background-color: #FBFBFB;
    }
}

.add-ambassador-btn{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 2em;
    margin-bottom: 2em;
   
}

@media (min-width: 1024px) {
    .ambassador-info .MuiFormControl-root {
        width: 95vw !important;
    }

}